import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import mAxiosApi from "@/api";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:codeArk?",
    redirect: to => ({
      path: '/sign-in/'+to.params.codeArk,
  }),
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in/:codeArk?",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-in/:codeArk/:redirect/:token?",
        name: "sign-in-redirect",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/password/:token",
        name: "password",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/ChangePassword.vue"),
      },
    ],
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
  {
    path: "/loader/:redirect/:token?",
    name: "loader",
    component: () => import("@/views/Loader.vue"),
  },
 
  {
    path: "/",
    redirect: "/networks",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/board",
        name: "board",
        redirect: "/board/overview",
        component: () => import("@/views/still/deploy/deploy.vue"),
      },
      {
        path: "/profil",
        name: "profil",
        component: () => import("@/views/still/Profil.vue"),
      },
      {
        path: "/networks",
        name: "networks",
        component: () => import("@/views/still/meraki/networks.vue"),
      },
      {
        path: "/addNetwork",
        name: "addNetwork",
        component: () => import("@/views/still/meraki/addNetwork.vue"),
      },
      {
        path: "/overviewFirewall",
        name: "overviewFirewall",
        component: () => import("@/views/still/meraki/overviewFirewall.vue"),
      },
      {
        path: "/parametrage",
        name: "parametrage",
        component: () => import("@/views/still/parametrage.vue"),
      },
      {
        path: "/admin",
        name: "admin",
        component: () => import("@/views/still/meraki/admin.vue"),
      },
    ],
  },

];


const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Réinitialisation de la configuration de la mise en page et vérification de l'authentification
  await store.commit(Mutations.RESET_LAYOUT_CONFIG);
  await store.dispatch(Actions.VERIFY_AUTH);

  setTimeout(() => { window.scrollTo(0, 0); }, 100);

  // Vérifie si l'utilisateur est authentifié
  if (!store.getters.isUserAuthenticated) {
    // Si l'utilisateur n'est pas authentifié, redirigez vers 'sign-in'
    if (to.name !== 'sign-in') {
      next({ name: 'sign-in', params: { codeArk: to.params.codeArk || '' } });
    } else {
      next(); // continue si déjà sur la page de connexion
    }
  } else {
    next(); // continue avec la navigation normale si authentifié
  }
});

export default router;


export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}