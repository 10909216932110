import mAxios from "axios";
import router from '../router';

mAxios.defaults.withCredentials = true; 

const mAxiosApi = mAxios.create({
  baseURL: process.env.VUE_APP_BACK_URL ? process.env.VUE_APP_BACK_URL : window.location.protocol+"//"+window.location.host+"/sv3",
  withCredentials: true,
});

mAxiosApi.interceptors.response.use(
  function (response) {
    if (response.headers["x-xsrf-token"]) {
      document.cookie =
        "XSRF-TOKEN=" + response.headers["x-xsrf-token"] + "; path=/";
    }
    return response;
  },
  function (error) {
    console.log('mYerror', error);
    if (
      error.message.indexOf("Error") > 0 ||
      (error.message.indexOf(" 40") > 0 && window.location.hash !== "#/profil" )
    ) {

      router.push({ name: "sign-in" });

      /*
        const urlSign = `${
          window.location.protocol + "//" + window.location.host
        }/#/sign-in`;
      */
      //window.location.href = urlSign;
      //alert('Erreur Base de données');
      /*
      Swal.fire({
        text: "Votre session semble avoir expirée, merci de vous reconnecter, svp",
        icon: "warning",
        buttonsStyling: false,
        confirmButtonText: "Retourner sur la page de connexion",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
        },
      }).then((isConfirm) => {
        const urlSign = `${
          window.location.protocol + "//" + window.location.host
        }/#/sign-in`;
        if (isConfirm) {
          window.location.href = urlSign;
        } else {
          window.location.href = urlSign;
        }
      });
      */
    }
  }
);

export default mAxiosApi;
