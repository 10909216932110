import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

interface Bordereau {
  bordSeq: string;
  bordType: string;
}

interface StoreInfo {
  bordereau: Bordereau;
}

@Module
export default class BordereauModule extends VuexModule implements StoreInfo {
  bordereau = {} as Bordereau;
  organisation = {} as any;
  refreshBord = 0 as number;

  /**
   * Get Bordereau object for current page
   * @returns object
   */
  get getBordereau(): Bordereau {
    return this.bordereau;
  }

  /**
   * Get breadcrumb array for current page
   * @returns object
   */
  get bordSeq(): string {
    const currentBord = localStorage.getItem("currentBord") || "0";
    //console.log("currentBord",currentBord)
    if (!this.bordereau.bordSeq && currentBord ) return currentBord;
    return this.bordereau.bordSeq;
  }

  get getRefreshBord(): number {
    return this.refreshBord;
  }

  get getOrg(): any {
    return this.organisation;
  }

  /**
   * Get current page title
   * @returns string
   */
  get bordType(): string {
    const currentBordType = localStorage.getItem("currentBordType") || "";
    //console.log("currentBordType",currentBordType)
    if (!this.bordereau.bordType && currentBordType ) return currentBordType;
    return this.bordereau.bordType;
  }

  @Mutation
  [Mutations.SET_BORDEREAU_MUTATION](payload) {
    this.bordereau = payload;
  }

  @Mutation
  [Mutations.SET_REFRESH_BORDEREAUX_MUTATION](payload) {
    this.refreshBord = payload;
  }

  @Mutation
  [Mutations.SET_ORG_MUTATION](payload) {
    this.organisation = payload;
  }

  @Action
  [Actions.SET_CURRENT_BORD](payload) {
    this.context.commit(Mutations.SET_BORDEREAU_MUTATION, payload);
  }

  @Action
  [Actions.SET_CURRENT_ORG](payload) {
    this.context.commit(Mutations.SET_ORG_MUTATION, payload);
  }


  @Action
  [Actions.SET_INCREMENT_REFRESH_BORDEREAU]() {
    this.context.commit(Mutations.SET_REFRESH_BORDEREAUX_MUTATION, this.refreshBord+1);
  }
}
